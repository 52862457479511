<template>
  <div>
  <div id="password-content" class="container-sm mt-sm-5">
    <div id="passwordForm">
      <div class="col-2"></div>
      <div id="center" class="col-8">
        <h1 class="PW-header">Forgot Password?</h1>
        <p class="PW-text">
          Type the email used during registration and we’ll send you a link to
          reset your password.
        </p>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <div class="form-group">
              <label class="label-email" for="email">Email</label>
              <ValidationProvider mode="lazy" rules="required|email" v-slot="{ errors }">
                <input
                  type="email"
                  v-model="email"
                  name="email"
                  id="email"
                  class="form-control"
                  @focus="error_list = []"
                  autofocus
                />
                <span id="error">{{ errors[0] }}</span>
                <div style="color:red;" v-for="error in error_list" :key="error.error_code">
                  {{ error.error_message}}
                </div>
              </ValidationProvider>
            </div>
            <div>
              <!-- @click="$bvModal.show('bv-modal-example')" -->
              <div>
                <b-button
                  id="show-btn"
                  class="instruction-button"
                  @click="handleSubmit(onSubmit)"
                  >Send me Instruction</b-button
                >
                <b-modal id="bv-modal-example" hide-footer>
                  <template class="modalTitle" #modal-title>
                    Reset email sent
                  </template>
                  <div class="d-block">
                    <p>
                      An email has been sent to the address you provided with
                      instructions to reset your password. If you're not seeing
                      it in your inbox check your spam folder.
                    </p>
                  </div>
                  <b-button
                    class="mt-3"
                    block
                    @click="$bvModal.hide('bv-modal-example')"
                    >OK</b-button
                  >
                </b-modal>
              </div>
            </div>
          </form>
        </ValidationObserver>
        <p>
          Don’t have an Account?
          <router-link
            variant="link"
            :to="{ path: `/${lang_info.lang_code}/registration` }"
            class="register-here-btn"
            >Register here</router-link
          >
        </p>
        <p class="support-text">
          Having Trouble? Contact<a
           href="mailto:TexasOnCourseSupport@highered.texas.gov?subject=Tomorrow Ready Texas Support Request"
            class="support-link"
          >
            Support</a
          >
        </p>
      </div>
    </div>
  </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { validation } from "../_helpers/validation";
import { router } from "../_helpers";
import {jwtService} from "../_services";

export default {
  title: "Forgot password | Tomorrow Ready Texas",
  name: "forgot_password",
  data() {
    return {
      email: "",
      submitted: false,
      error_list: [],
      lang_stanza_key: 'password_forgot',
      lang_stanza: {}, 
      lang_info: {}
    };
  },
  async created() {

    this.lang_info = await this.getLangInfo();
    this.lang_stanza = await this.getLangStanza({
      "lang_code": this.lang_info["lang_code"],
      "lang_stanza_key": this.lang_stanza_key
    });

    document.body.style.backgroundColor = "#F5F7FA";
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    ...mapActions("account", [
          "requestPasswordReset"
    ]),
    ...mapActions('language', ['getLangInfo', 'getLangStanza']),
    async onSubmit() {
      this.error_list = [];
      this.submitted = true;
      const { email} = this;
      const default_jwt_token_expire_seconds = 157788000;
      if (email) {
        const param_dict = {"email": email,
                            "expires": default_jwt_token_expire_seconds
                           }

        await this.requestPasswordReset(param_dict);
        if (this.request_password_reset_result_dict.error_list.length > 0) {
          this.request_password_reset_result_dict.error_list.forEach(error => {
          });
          this.error_list = this.request_password_reset_result_dict.error_list;
        } else {
          this.$bvModal.show('bv-modal-example');
        }
      }
    },
  },
  computed: {
    ...mapState("account", ["request_password_reset_result_dict"]),
  },
};
</script>

<style lang="scss">
#password-content {
  border-radius: 10px;
}

#center {
  margin-right: auto;
  margin-left: auto;
}

#passwordForm {
  min-width: 664px !important;
  min-height: 720px !important;
  border-radius: 10px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
}

.PW-header {
  font-size: 40px;
  font-weight: bold;
  float: left;
  margin-right: 89px;
  margin-top: 112px;
}

.PW-text {
  text-align: left;
  margin-bottom: 29px;
}

.form-group > label {
  float: left;
}

.label-email {
  font-size: 18px;
}

.form-control {
  box-sizing: border-box;
  height: 64px;
  max-width: 448px;
  border: 1px solid #c2c6cc;
  border-radius: 4px;
  background-color: #ffffff;
}

.form-control:focus {
  box-shadow: none;
  border-color: #7A7A7A;
}

.form-control:hover {
    border-color: #1a6edb;
}


.instruction-button {
  font-weight: bold;
  margin-top: 48px !important;
  margin-bottom: 28px !important;
  margin: 0 auto;
  display: block;
  background: #f15a2a;
  height: 48px;
  width: 303px;
  border-radius: 16px;
  border-color: #f15a2a;
  background-color: #f15a2a;
  font-size: 19px;
}

.instruction-button:hover {
  background-color: #f1662a;
  border-color: #f1662a;
}

.instruction-button:active {
  background-color: #e85127 !important;
  border-color: #e85127 !important;
}

.instruction-button:focus {
  background-color: #e85127 !important;
  border-color: #e85127 !important;
  box-shadow: none;
}

#error {
  color: #ec040f;
  font-family: Lato;
  font-size: 16px;
  position: static;
  float: right;
  margin-right: -10px;
}

.modal-content {
  height: 292px;
  width: 574px;
  border-radius: 10px;
  background-color: #ffffff;
}

.modal-title {
  color: #292a2c;
  font-family: Lato;
  font-size: 24px !important;
  font-weight: bold;
  margin-top: 13px;
  margin-left: 36px;
}

.modal-header {
  border-bottom: none !important;
  padding-bottom: 8px;
}

.modal-body {
  padding: 0px;
}

.d-block > p {
  color: #292a2c;
  font-family: Lato;
  font-size: 16px;
  padding-left: 51px;
  padding-right: 45px;
  padding-bottom: 50px;
  border-bottom: 1px solid lightgray;
  margin-bottom: 32px;
}

.mt-3 {
  margin: 0.25rem;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  height: 40px;
  width: 105px;
  border-radius: 16px;
  background-color: #274579 !important;
  color: #ffffff;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
}

.password-button:hover {
  background-color: #f1662b;
}

.vl {
  border-left: 1px solid lightgray;
  height: 150px;
}

.register-here-btn,
.support-link {
  color: #1a6edb;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
}

.support-text {
  margin-top: 140px;
  margin-bottom: 72px !important;
}
</style>
